import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxDefault from '../../templates/mdx-default.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxDefault;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <section className="main style1">
      <header className="small">
        <h1 {...{
          "id": "ivan-williams"
        }}>{`Ivan Williams`}</h1>
        <h3 {...{
          "id": "emrg-producer"
        }}>{`EMRG Producer`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "block",
              "width": "60%",
              "marginLeft": "auto",
              "marginRight": "auto",
              "paddingTop": "0.75rem"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1171px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAAB7yjE3BWpmjNJsEf/xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAxATIf/aAAgBAQABBQID1gqypVwjTOehYsy5Gr//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BERi//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8BKFsf/8QAGhAAAgMBAQAAAAAAAAAAAAAAASEAEBECYf/aAAgBAQAGPwJnIureUB7HX//EABsQAAMBAAMBAAAAAAAAAAAAAAABESFBUWFx/9oACAEBAAE/IUOZGcv32jGDxP6KWqm44Qn7i6DddjEop//aAAwDAQACAAMAAAAQv/B9/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERsf/aAAgBAwEBPxCIa6Nr/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREKH/2gAIAQIBAT8QdswvJEf/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMUFR/9oACAEBAAE/EASQe2Omky6JDov10AGThlM3Hg0syfEZ/XHEECgfMHBQ4UsN/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "ivan williams 1x1",
                "title": "ivan williams 1x1",
                "src": "/static/2f024f45bd6ce0d93f5b47eff035b703/6cd7b/ivan-williams_1x1.jpg",
                "srcSet": ["/static/2f024f45bd6ce0d93f5b47eff035b703/3ad8d/ivan-williams_1x1.jpg 600w", "/static/2f024f45bd6ce0d93f5b47eff035b703/6cd7b/ivan-williams_1x1.jpg 1171w"],
                "sizes": "(max-width: 1171px) 100vw, 1171px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></p>
      </header>
      <div className="inner">
        <p><strong parentName="p">{`Ivan Williams`}</strong>{` is a film, TV, and theatre executive producer with a passion for the arts. As a media and entertainment entrepreneur, he supports the deployment of state-of-the-art technology to streamline and diversify the development and presentation of new shows and films. Before becoming a filmmaker, he had a 35 year career as a chemical engineer and senior business leader with major energy related companies, including ARCO, BP and Worley Parsons.`}</p>
        <p>{`In Hollywood, Ivan has taken an ownership position in an independent film studio, helped launch a media technology company, and produced multiple entertainment projects. This includes executive producing films such as KNIGHTS OF BADASSDOM that starred Ryan Kwanten, Margarita Levieva, Steve Zahn, Summer Glau, Jimmi Simpson, Danny Pudi, and Peter Dinklage, BIG STONE GAP with Ashley Judd, Whoopi Goldberg, Patrick Wilson, Jenna Elfman, Anthony LaPaglia, John Benjamin Hickey and Jasmine Guy, a short film called THE SHIFT that was directed by Italian fashion industry filmmaker Francisco Calebrese, and co-producing the film M.F.A. starring Francesca Eastwood and Clifton Collins Junior, irst runner up for the Best Film at the 2017 South by Southwest Film Festival. `}</p>
        <p>{`In 2018, Ivan was on the team that created THIS CHANGES EVERYTHING, the acclaimed Geena Davis executive produced documentary about gender misrepresentation in Hollywood. This film was the first runner up for the Toronto International Film Festival’s Grolsch People’s Choice Award for Best Documentary. `}</p>
        <p>{`Currently, Ivan is working with the director of THIS CHANGES EVERYTHING on a film and social impact campaign called WOMEN OF WALL STREET, addressing gender and diversity issues within the world of high finance. In addition to this, Ivan is executive producing a biopic called LILLY and its associated social impact campaign. LILLY is about Lilly Ledbetter and how she became the catalyst for the Lilly Ledbetter Fair Pay Act of 2009.`}</p>
        <p>{`Beyond films, Ivan also executive produced the Broadway musical and original cast recording of FIRST DATE starring Zachary Levi and Krysta Rodriguez. He also executive produced an applauded Web TV series with Alexandra Roxo and Natalia Leite called BE HERE NOWISH SEASON 2. `}</p>
        <p>{`At the University of California, Irvine (UCI), Ivan has found a welcome home. In addition to his work as Executive Producer in the Emergent Media Research Group, he is on the UCI Foundation Board of Trustees, Honors College Executive Roundtable, chairman of the Advisory Board of the International Brain Initiative, leadership board of the Claire Trevor Society, co-chairman of the Cybersecurity Policy & Research Institute executive board, and chairman of the Dean’s Arts Board of the Claire Trevor School of the Arts. Additionally, he has been named a community member of the Graduate Division’s Dean’s Leadership Council and the School of Humanities Dean’s Leadership Council. Ivan even occasionally steps into the classroom as an adjunct professor to give lectures on arts management, creativity and entrepreneurship. `}</p>
        <p>{`Ivan is president of the global UCI Anteaters in the Arts alumni group, a veteran U.S. Naval Reserve intelligence officer and a member of the Producers Guild of America, Sundance Institute, and Film Independent. He holds a B.Sc. in chemical engineering from Oregon State University and was a Dean’s Scholar at UCI’s Paul Merage School of Business, where he received his M.B.A.`}</p>
        <p>{`Outside the university hallways, Ivan sits on the boards of several organizations, including Apples and Oranges Arts Broadway Theater Studios, Broadway Dreams Theater Actors Training Program, and ScriptHop’s A.I. script handling technology company.`}</p>
        <p>{`In 2020, Ivan was invited to serve on the National Organizing Coalition of Virus Information Distribution (`}<a parentName="p" {...{
            "href": "https://nocovid.us"
          }}>{`https://nocovid.us`}</a>{`). a volunteer committee consisting of former senior White House officials, cabinet secretaries, governors, medical experts, sports executives, and media leaders who are focused on making sure all Americans get clear and consistent communications about COVID-19. Their work has advances public awareness of COVID-19 by initiating and creating public service announcements with celebrities, sport figures, academics, and public health leaders including Dr. Anthony Fauci.`}</p>
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      